<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'基础数据报表'"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="5">
              门店：
              <el-select
                style="width: 100% !important;"
                v-model="searchData.storeCode"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请搜索选择门店"
                :remote-method="searchStore"
                :loading="selLoading">
                <el-option
                  v-for="item in storeList"
                  :key="item.storeCode"
                  :label="item.storeName"
                  :value="item.storeCode">
                </el-option>
              </el-select>
            </el-col>

            <el-col :span="5">
              门店类型：
              <el-select
                style="width: 100% !important;"
                v-model="searchData.storeType"
                clearable
                filterable
                remote
                reserve-keyword
                placeholder="请搜索选择门店类型"
              >
                <el-option
                  v-for="item in storeTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>


          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="5">
              <div class="search_date">
                <el-radio-group v-model="searchData.timeFrameType" size="small" @change="pageHandler(params, 1, 'tab')" style="width: 100%;">
                  <el-radio-button style="width: 50%;" label="date">按日查询</el-radio-button>
                  <el-radio-button style="width: 50%;" label="month">按月查询</el-radio-button>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="search_date">
                <el-date-picker
                  v-if="searchData.timeFrameType=='date'"
                  style="width: 100%;"
                  v-model="dayDateArr"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyyMMdd"
                  @change="daySelChange"
                  :picker-options="pickerOptions">
                </el-date-picker>
                <el-date-picker
                  v-if="searchData.timeFrameType=='month'"
                  style="width: 100%;"
                  v-model="monthDateArr"
                  type="monthrange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  format="yyyy-MM"
                  value-format="yyyyMM"
                  @change="monthSelChange"
                  :picker-options="pickerOptionsMonth">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="4" :offset="4">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <template #navBar>
        <div v-if="searchData.timeFrameType=='date'" class="table_top_box">{{numDateToDate(searchData.startTime)+'日 至 '+numDateToDate(searchData.endTime)}}日基盘客户数据</div>
        <div v-else class="table_top_box">{{searchData.startTime+'月 至 '+searchData.endTime}}月基盘客户数据</div>
      </template>
      <!-- 表格本体 -->
      <el-table-column label="门店" fixed="left" align="center" prop="storeName" :min-width="180">
        <!-- <template slot-scope="scope">
          <div class="table_box_custom" @click="openUserInfoFn(scope.row)">
            <img class="default_head" src="https://taian-oss.dasmaster.com/wechat/default_head_admin_table.png" />
            <div class="user_name">
              <div>{{scope.row.customerName}}</div>
              <div>--</div>
            </div>
          </div>
        </template> -->
      </el-table-column>
      <span v-for="(item, index) in listTableData" :key="index">
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" align="center">
          <template slot-scope="scope">
            <div v-if="item.prop=='repurchaseProportion'">{{ scope.row[item.prop] }}%</div>
            <div v-else-if="item.prop=='repurchasePeriod'">{{ scope.row[item.prop] }}天</div>
            <div v-else>{{ scope.row[item.prop] }}</div>
          </template>
        </el-table-column>
      </span>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
//import引入的组件需要注入到对象中才能使用
import { formatDate,numDateToDate } from "@/utils/common"
export default {
  components: {  },
  data() {
    //这里存放数据
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      pickerOptionsMonth: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      listData: [],
      listTableData: [
        // { label: "门店", width: 100, prop: "customerName" },
        { label: "服务台次", width: 120, prop: "serviceCount" },
        { label: "客户数", width: 120, prop: "customerNum" },
        { label: "新客户", width: 100, prop: "newCustomerNum" },
        { label: "新客户服务台次", width: 150, prop: "newCustomerServiceCount" },
        { label: "老客户", width: 100, prop: "oldCustomerNum" },
        { label: "老客户服务台次", width: 150, prop: "oldCustomerServiceCount" },
        { label: "总实际营收", width: 120, prop: "totalGmv" },
        { label: "现金收入", width: 180, prop: "cashGmv" },
        { label: "储值卡抵扣收入", width: 180, prop: "depositCardGmv" },
        { label: "套餐卡抵扣收入", width: 180, prop: "setCardGmv" },
        { label: "复购客户占比", width: 180, prop: "repurchaseProportion" },
        { label: "复购周期", width: 150, prop: "repurchasePeriod" },
      ],
      storeList: [],

      storeTypeList: [
        {value: 8, name: "卫星店"},
        {value: 3, name: "工坊店"},
        {value: 7, name: "售后服务中心"},
        {value: 9, name: "体验中心"}
      ],
      searchData: {
        storeCode: '',
        storeType: '',
        startTime: '',
        endTime: '',
        timeFrameType: 'date',            //date-按日,month-按月;
      },
      listLoading: false,
      selLoading: false,
      dayDateArr: [],
      monthDateArr: []
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 页面列表翻页
    pageHandler(params, num, type) {
      if(type == 'tab'){
        this.defaultDateFn();
      }
      if(type == 'reset'){
        this.searchData.storeCode = '';
        this.searchData.storeType = '';
        this.defaultDateFn();
      }
      this.listLoading = true;
      this.$store.dispatch('management/getBasicsDataList',{
        ...this.searchData,
        pageNum: num ? num : params.pageNum,
        pageSize: params.pageSize
      }).then(res => {
        this.listData = res.data.list;
        let a = {
          serviceCount: 0,                    //服务台次
          customerNum: 0,                     //客户数
          newCustomerNum: 0,                  //新客户
          newCustomerServiceCount: 0,         //新客户服务台次
          oldCustomerNum: 0,                  //老客户
          oldCustomerServiceCount: 0,         //老客户服务台次
          totalGmv: 0,                        //总实际营收
          cashGmv: 0,                         //现金收入
          depositCardGmv: 0,                  //储值卡抵扣收入
          setCardGmv: 0,                      //套餐卡抵扣收入
        }
        Object.keys(a).forEach(key => {
          this.listData.forEach(item => {
            a[key] = (a[key]+(Number(item[key]||0)*100));
          })
          a[key] = a[key]/100
        })
        a.storeName = '合计';
        a.repurchaseProportion = '-';
        a.repurchasePeriod = '-';
        this.listData.push(a)
        params.render(res.data.total);
        this.listLoading = false;
      });
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData.storeCode = '';
      this.defaultDateFn();
    },
    // 时间类型选择
    tabChange(e){
      this.defaultDateFn();
      
    },
    // 日期选择
    daySelChange(e){
      console.log(e)
      this.searchData.startTime = e[0];
      this.searchData.endTime = e[1];
      this.monthDateArr = [];
    },
    // 月份选择
    monthSelChange(e){
      console.log(e)
      this.searchData.startTime = e[0];
      this.searchData.endTime = e[1];
      this.dayDateArr = [];
    },
    // 默认时间赋值
    defaultDateFn(){
      let defaultDate = formatDate().date2;
      let defaultMonth = formatDate().month2
      if(this.searchData.timeFrameType == 'date'){
        this.searchData.startTime = defaultDate[0];
        this.searchData.endTime = defaultDate[1];
        this.dayDateArr = defaultDate;
        this.monthDateArr = [];
      }
      if(this.searchData.timeFrameType == 'month'){
        this.searchData.startTime = defaultMonth[0];
        this.searchData.endTime = defaultMonth[1];
        this.monthDateArr = defaultMonth;
        this.dayDateArr = [];
      }
    },
    // 时间格式转换
    numDateToDate(date){
      return numDateToDate(date)
    },






    /**
     * 接口方法
     */
    // 门店搜索
    searchStore(val = "") {
      this.selLoading = true;
      this.$store.dispatch('management/getStoreList',{
        pageNum: 1,
        pageSize: 50,
        storeName: val,
        storeTypeList: ["3", "8", "7","9"]
      }).then(res => {
        this.selLoading = false;
        this.storeList = res.data.list || [];
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.defaultDateFn();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.searchStore();
  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {}
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .table-box{
  padding: 0 !important;
}
.page_box {
  .search_date{
    display: flex;
    width: 100%;
  }
  .table_top_box {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: -2px;
    box-sizing: border-box;
    font-weight: bold;
  }
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_box_custom {
    display: flex;
    align-items: center;
    cursor: pointer;
    .default_head {
      width: 60px;
      height: 60px;
      display: block;
      border-radius: 100%;
      margin-right: 10px;
    }
    .user_name {
      > div:nth-child(1) {
        color: #0083ff;
        font-size: 14px;
      }
    }
  }
  .container_box {
    background: #f1f5f7;
    height: 100%;
    padding: 25px;
    box-sizing: border-box;
    .container_box_head {
      .label {
        color: #595959;
        line-height: 30px;
      }

      .value {
        color: #262626;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
      }
      background: #fff;
      margin-bottom: 25px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      height: auto;
      .head_01{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_01_left{
          display: flex;
          align-items: center;
          .left_img{
            width: 70px;
            height: 70px;
            border-radius: 12px;
            margin-right: 20px;
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .left_info{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70px;
            .left_info_top{
              display: flex;
              align-items: center;
              >div{
                margin-right: 15px;
                font-size: 16px;
                font-weight: bold;
              }
              >i{
                margin-right: 10px;
                font-size: 18px;
                color: #d2d2d2;
                font-weight: bold;
              }
            }
            .left_info_down{
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              cursor: pointer;
              >div{
                margin-right: 15px;
                font-size: 12px;
                color: #74788d;
                text{
                  color: #409eff;
                }
              }
            }
          }
        }
      }
      .head_02{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0;
        .head_02_right,.head_02_left{
          width: 50%;
        }
        .head_02_left{
          border-right: 1px solid #e7e7e7;
          box-sizing: border-box;
          .left_item{
            display: flex;
            align-items: center;
            .left_people{
              display: flex;
              align-items: center;
              margin-right: 30px;
              .left_item_people{
                display: flex;
                align-items: center;
                font-size: 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                padding: 4px 5px;
                img{
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  margin-right: 5px;
                }
              }
            }
            .left_item_btn{
              display: flex;
              align-items: center;
            }
          }
        }
        .head_02_right{
          padding-left: 25px;
          .right_item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
              display: flex;
              align-items: center;
            }
            .tag_box{
              overflow: hidden;
              width: 300px;
              white-space: nowrap;
              .el-tag{
                margin-right: 5px;
              }
            }
            .tag_hint{
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
      .head_03{
        display: flex;
        align-items: center;
        font-size: 12px;
        >div{
          margin-right: 20px;
        }
      }
    }
    .container_box_aside {
      background: #fff;
      margin-right: 20px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      overflow: scroll;
      max-height: 644px;
      .container_box_aside_head{
        display: flex;
        margin-bottom: 10px;
        i{
          margin-top: 3px;
          margin-right: 5px;
        }
        .container_box_aside_title{
          font-size: 14px;
          font-weight: bold;
          color: #333;
          >div:nth-child(2){
            color: rgba(80, 93, 105, .6);
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
      .container_box_aside_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 13px;
        >div:first-child{
          color: #74788d;
          width: 110px;
          flex-shrink: 0;
        }
        >div:last-child{
          flex: 1;
          color: #505d69;
          text-align: end;
        }
      }
    }
    .container_box_main {
      background: #fff;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 0 !important;
      .container_box_main_tabs{
        margin-left: -1px;
      }
      .container_box_main_content{
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .button_group{
          width: 100%;
          overflow: scroll;
          display: flex;
          align-items: center;
          >.button_group_item{
            margin: 0 !important;
            margin-right: -1px !important;
          }
          >.button_group_item:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          >.button_group_item:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .button_group::-webkit-scrollbar {
          display: none;
        }
      }
      // 客户旅程
      .client_itinerary{
        padding: 10px 50px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: scroll;
        .client_itinerary_title{
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          >div:nth-child(2){
            font-size: 13px;
            color: #1883ff;
            border: 1px solid #1883ff;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }
        .client_itinerary_dot{
          margin-left: -19px;
          margin-top: 8px;
          background: #fff;
          padding: 10px 0;
          text-align: center;
          font-size: 12px;
          color: #74788d;
          white-space: pre-line;
          .client_itinerary_dot_icon{
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 5px;
          }
          .client_itinerary_dot_circle{
            width: 11px;
            height: 11px;
            padding-top: 30px;
            position: absolute;
            top: -30px;
            left: -5px;
            background: #fff;
            .circle{
              width: 11px;
              height: 11px;
              border-radius: 100%;
              border: 1px solid #adb5bd;
              background: #f8f9fa;
              box-sizing: border-box;
            }
          }
        }
        .client_itinerary_dot_first{
          margin-top: 23px !important;
        }
        .client_itinerary_dot_html{
          white-space: pre-wrap !important;
          background: #f8f9fa;
          font-size: 13px;
          line-height: 20px;
          padding: 12px;
          box-sizing: border-box;
          margin: 10px 0;
          color: #505d69;
        }
        .client_itinerary_dot_operator{
          display: flex;
          align-items: center;
          font-size: 13px;
          img{
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            margin-right: 10px;
          }
          >div:nth-child(2){
            margin-right: 20px;
            color: #505d69;
          }
          >div:nth-child(3){
            color: #adb5bd;
          }
        }
      }
      // 客户旅程外另四个列表
      .table_model_box{
        padding: 0 15px;
        box-sizing: border-box;
      }
    }
  }
}
::v-deep .el-dialog__body{
  padding-top: 0 !important;
}
::v-deep .el-descriptions__header{
  margin: 10px 0px !important;
}
::v-deep .el-descriptions__title{
  font-size: 13px !important;
}
::v-deep .el-descriptions__body{
  padding: 0 20px !important;
}
::v-deep .el-timeline-item__tail{
  border-left: 1px solid #dcdee0 !important;
  left: 0 !important;
}
::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
  display: initial !important;
}
::v-deep .el-timeline:last-child .el-timeline-item:last-child .el-timeline-item__tail{
  display: none !important;
}
::v-deep .el-timeline{
  margin-bottom: 30px !important;
}
::v-deep .el-timeline-item__timestamp{
  font-size: 16px !important;
  color: #505d69 !important;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .el-timeline-item__wrapper{
  padding-left: 50px !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 3px solid transparent;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
  border-left: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-top: 3px solid #2278f5;
  box-sizing: border-box;
}
::v-deep .el-input-group__prepend {
  background-color: #fff !important;
}
::v-deep .el-table__header thead tr th:first-of-type {
  padding-left: 0;
}
.el-select {
  width: 110px !important;
}
::v-deep .el-radio-button__inner{
  width: 100% !important;
}
</style>
